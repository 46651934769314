import Performance from '@ebac-online/web-meter/Performance';
import EventEmitter from '@ebac-online/web-meter/EventEmitter';
import Meter from '@ebac-online/web-meter/Meter';
import MemoryQueue from '@ebac-online/web-meter/MemoryQueue';
import BeaconProcessor from '@ebac-online/web-meter/BeaconProcessor';
import BatchExporter from '@ebac-online/web-meter/BatchExporter';
import UrlChangeExporter from '@ebac-online/web-meter/UrlChangeExporter';
import VisibilityChangeExporter from '@ebac-online/web-meter/VisibilityChangeExporter';
// import StreamExporter from '@ebac-online/web-meter/StreamExporter';
// import ConsoleProcessor from '@ebac-online/web-meter/ConsoleProcessor';

const isDev = process.env.NODE_ENV !== 'production';

export default ({ $config }) => {
  const beaconProcessor = new BeaconProcessor({
    url: $config.METRICS_URL,
  });

  const performanceQueue = new MemoryQueue();
  performanceQueue.registerExporter(new UrlChangeExporter(beaconProcessor));
  performanceQueue.registerExporter(
    new VisibilityChangeExporter(beaconProcessor),
  );
  performanceQueue.registerExporter(new BatchExporter(beaconProcessor));

  const eventEmitterQueue = new MemoryQueue();
  eventEmitterQueue.registerExporter(new UrlChangeExporter(beaconProcessor));
  eventEmitterQueue.registerExporter(
    new VisibilityChangeExporter(beaconProcessor),
  );
  eventEmitterQueue.registerExporter(new BatchExporter(beaconProcessor));

  const handlePagePath = (pagePath) => {
    if (/\/webinars\/[\S]+\/[\S]+\/thankyou/.test(pagePath)) {
      return '/webinars/_id/_id/thankyou';
    } else if (/\/webinars\/[\S]+\/[\S]+/.test(pagePath)) {
      return '/webinars/_id/_id';
    } else if (/\/webinars\/[\S]+/.test(pagePath)) {
      return '/webinars/_id';
    }
    if (/\/eventos\/[\S]+\/[\S]+\/thankyou/.test(pagePath)) {
      return '/eventos/_id/_id/thankyou';
    } else if (/\/eventos\/[\S]+\/[\S]+/.test(pagePath)) {
      return '/eventos/_id/_id';
    } else if (/\/eventos\/[\S]+/.test(pagePath)) {
      return '/eventos/_id';
    }

    if (/\/offers\/[\S]+/.test(pagePath)) {
      return '/offers/_id';
    }

    if (/\/offers-updated\/[\S]+/.test(pagePath)) {
      return '/offers-updated/_id';
    }

    if (/\/loan-request\/[\S]+/.test(pagePath)) {
      return '/loan-request/_id';
    }

    return pagePath;
  };

  const performance = new Performance({
    queue: performanceQueue,
    handlePagePath,
  });

  const eventEmitter = new EventEmitter({
    queue: eventEmitterQueue,
    gtmDecorator: true,
    handleGtmEventName: (name, event) => {
      let processedName = name;
      if (event['gtm.eventAction'] && event['gtm.eventCategory']) {
        processedName = `${name}_${event['gtm.eventCategory']}_${event['gtm.eventAction']}`;
      }
      return processedName;
    },
    handlePagePath,
  });

  const meter = new Meter({
    meterName: 'web-meter',
    performance,
    eventEmitter,
    customAttributes: {
      env: isDev ? 'local' : 'prod',
      country: $config.COUNTRY,
    },
    eventNamePrefix: 'public_site_',
  });

  window.$meter = meter;
};
